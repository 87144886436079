import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Delete, Get, Post, Put } from "../Api/api";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import {
  capitalizeFirstLetter,
  formatCurrency,
} from "../Supporting files/HelpingFunction";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";
import ProductsList from "../components/ProductsList";

function valuetext(value) {
  return `${value}°C`;
}

const ProductsData = () => {
  const cartData = useSelector((state) => state.user.cart);
  const wishlistData = useSelector((state) => state?.user?.wishlist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get("categoryId");
  const [value, setValue] = useState([0, 5000]);
  const [productStats, setproductStats] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [allProducts, setallProducts] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    let catId = [...checkedCategories];
    catId.push(categoryId);
    setCheckedCategories(catId);
  }, [categoryId]);

  const handleCheckboxChange = (id) => {
    setCheckedCategories((prevCheckedCategories) => {
      const newCheckedCategories = prevCheckedCategories.includes(id)
        ? prevCheckedCategories.filter((categoryId) => categoryId !== id)
        : [...prevCheckedCategories, id];
      return newCheckedCategories;
    });
  };

  const handleColorClick = (color) => {
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.includes(color)
        ? prevSelectedColors.filter((selectedColor) => selectedColor !== color)
        : [...prevSelectedColors, color]
    );
  };
  const addToCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));

      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const removeFromCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.removeCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));
      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const deleteWishList = async (productId, variantId, sizeId) => {
    let payload = {
      productId,
      variantId,
      sizeId,
    };

    try {
      const response = await Delete(`${URL_CONSTANTS.deleteWishlist}`, payload);
      toast.success("Wishlist updated successfully");
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const addToWishlist = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddToWishlist}`,
        JSON.stringify(dto)
      );
      dispatch(updateWishlist(response.response.products));
      toast.success("Wishlist updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  useEffect(() => {
    GetProductStates();
    //GetAllProduct();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const GetProductStates = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getProductState);
      setValue([0, response.response.maxPrice]);
      setproductStats(response.response);
    } catch (error) {
      console.error("Error fetching getAllProducts:", error.message);
    }
  };
const GetAllProduct = async () => {
  try {
    const categories =
      checkedCategories.length > 0 ? checkedCategories.join(",") : "";
    const colors = selectedColors.map(encodeURIComponent).join(",");
    const min = value[0];
    const max = value[1];
    let query = `?min=${min}&max=${max}`;

    // If categories exist, append them to the query
    if (categories) {
      query += `&categories=${categories}`;
    }

    // If colors exist, append them to the query
    if (colors && colors.length > 0) {
      query += `&colors=${colors}`;
    }

    const url = `${URL_CONSTANTS.getAllProducts}${query}`;

    const response = await Get(url);
    setallProducts(response.response.products);
    setIsLoader(false);
  } catch (error) {
    console.error("Error fetching getAllProducts:", error.message);
  }
};

  useEffect(() => {
    const handler = setTimeout(() => {
      GetAllProduct();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [checkedCategories, selectedColors, value]);
  return (
    <>
      {isLoader && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="listSection">
          <Grid container padding={0} columnSpacing={2} rowSpacing={2}>
            <Grid xs={12} sm={12} md={4} lg={3} className="filter_accordians mb-3">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className="accr_header"
                >
                  <h2 className="heading">Price</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="panel_content">
                    <div className="pricing mt-6">
                      <p>From</p>
                      <p>To</p>
                    </div>
                    <div className="pricing mt-2 mb-4">
                      <SDTextField
                        title=""
                        value={value[0]}
                        value_update={() => { }}
                        cls="w-100"
                        style={{ maxWidth: "100px" }}
                        disable={true}
                      />
                      <div className="seprator"></div>
                      <SDTextField
                        title=""
                        value={value[1]}
                        value_update={() => { }}
                        cls="w-100"
                        style={{ maxWidth: "100px" }}
                        disable={true}
                      />
                    </div>
                    <Slider
                      className="pricePicker"
                      getAriaLabel={() => "Temperature range"}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      max={+productStats?.maxPrice}
                      min={0}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className="accr_header"
                >
                  <h2 className="heading">Select Category</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="panel_content">
                    <div className="categories mt-6">
                      {productStats?.categories?.map((data) => (
                        <FormControlLabel
                          key={data._id}
                          control={
                            <Checkbox
                              checked={checkedCategories.includes(data._id)}
                              onChange={() => handleCheckboxChange(data._id)}
                              name={data.title}
                              color="primary"
                            />
                          }
                          label={data.title}
                        />
                      ))}
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className="accr_header"
                >
                  <h2 className="heading">Select Color</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="panel_content">
                    <div className="colors pt-6 pb-2 px-1">
                      {productStats?.colors?.map((color) => (
                        <div
                          key={color}
                          className={`hex ${selectedColors.includes(color) ? "selected" : ""
                            }`}
                          style={{ background: color }}
                          onClick={() => handleColorClick(color)}
                        ></div>
                      ))}
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid xs={12} sm={12} md={8} lg={9}>
              <Box>
                <Grid container padding={0} columnSpacing={2} rowSpacing={2}>
                  {allProducts?.length > 0 && allProducts?.map((product) => {
                    let is_exist = cartData?.find(
                      (productData) =>
                        productData.sizeId ===
                        product?.variants[0]?.sizes[0]?._id
                    );
                    let is_WishlistExist = wishlistData?.find(
                      (productData) =>
                        productData.sizeId ===
                        product?.variants[0]?.sizes[0]?._id
                    );
                    let quantity = Number(product?.variants[0]?.sizes[0]?.quantity);
                    return (
                      <Grid xs={6} sm={6} md={6} lg={4} >
                        <div className="lists">
                          <img loading="eager"
                            className="list_banner"
                            src={product?.variants[0]?.images[0]}
                          />
                          <p className="pro_title">
                            {capitalizeFirstLetter(product.title)}
                          </p>
                          <p className="pro_rate mb-4">
                            {formatCurrency(product?.variants[0]?.sizes[0]?.price)}</p>
                          <Grid xs={12} className="ctaBtn cart_wish_btn">
                            <img loading="eager" className="cart_img"
                              src={is_exist ? "images/cart_full.png" : "images/cart_empty.png"}
                              onClick={() => {
                                if (!is_exist) {
                                  addToCart(
                                    product._id,
                                    product?.variants[0]?._id,
                                    product?.variants[0]?.sizes[0]?._id
                                  );
                                } else {
                                  removeFromCart(
                                    product._id,
                                    product?.variants[0]?._id,
                                    product?.variants[0]?.sizes[0]?._id
                                  );
                                }
                              }}
                            />
                            <Button
                              onClick={() =>
                                navigate(
                                  `${userRoutes.productDetail}?productId=${product._id}`
                                )
                              }
                              className="submitBtn xsm"
                            >
                              Buy Now
                            </Button>
                            <img loading="eager" className="cart_img"
                              src={is_WishlistExist ? "images/fav.svg" : "images/favWhite.svg"}
                              onClick={() => {
                                if (!is_WishlistExist) {
                                  addToWishlist(
                                    product._id,
                                    product?.variants[0]?._id,
                                    product?.variants[0]?.sizes[0]?._id
                                  );
                                } else {
                                  deleteWishList(
                                    product._id,
                                    product?.variants[0]?._id,
                                    product?.variants[0]?.sizes[0]?._id
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ProductsList
          apiUrl={URL_CONSTANTS.getNewArrival}
          text1={"Shop "}
          text2={"the New Stock"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.newArrivals}
        />
        <ProductsList
          apiUrl={URL_CONSTANTS.getBestSeller}
          text1={"Top"}
          text2={"Trending Items"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.bestSeller}
        />
        <Footer />
      </Box>
    </>
  );
};

export default ProductsData;