import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../commonSCSS/BannerSlider.scss"; // SCSS for styling
import { userRoutes } from "../Routes/UserRoutes";
import { useNavigate } from "react-router-dom";

const BannerSlider = () => {
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    afterChange: (current) => setActiveSlide(current), // Track active slide
  };

  return (
    <div className="banner-slider">
      {/* Logo on top-left */}
      <div className="logo">
        <img src={"/images/logo.png"} alt="Logo" />
      </div>

      {/* Navigation buttons on top-right */}
      <div
        className={`top-right-navigation ${
          activeSlide === 2 ? "white-menu" : ""
        }`}
      >
        <button
          className="nav-btn"
          onClick={() => navigate(userRoutes.newArrivals)}
        >
          New Arrivals
        </button>
        <button
          className="nav-btn"
          onClick={() => navigate(userRoutes.featuredProduts)}
        >
          Featured Products
        </button>
        <button
          className="nav-btn"
          onClick={() => navigate(userRoutes.bestSeller)}
        >
          Best Seller
        </button>
      </div>

      {/* Slider home page*/}
      <Slider {...settings}>
        {/* First Slide (Centered Text, Black Text) */}
        <div className="slider-item">
          <img
            src={"/Hero.png"}
            alt="Festive Delights"
            className="slider-image"
             loading="eager"
          />
          <div className="slider-content center-text black-text">
            <h1>Festive Delights</h1>
            <p>Enjoy Up to 50% Off This Season!</p>
            <button
              className="shop-now-btn"
              onClick={() => navigate(userRoutes.products)}
            >
              Shop Now
            </button>
          </div>
        </div>

        {/* Second Slide (Text on Left, Black Text) */}
        <div className="slider-item">
          <img
            src={"/Hero2.png"}
            alt="Festive Delights"
            className="slider-image"
             loading="eager"
          />
          <div className="slider-content left-text black-text">
            <h1>Bestseller</h1>
            <p>Explore our bestselling products</p>
            <button
              className="shop-now-btn"
              onClick={() => navigate(userRoutes.bestSeller)}
            >
              Shop Now
            </button>
          </div>
        </div>

        {/* Third Slide (Text on Left, White Text, Black BG) */}
        <div className="slider-item">
          <img
            src={"/Hero3.png"}
            alt="Festive Delights"
            className="slider-image"
             loading="eager"
          />
          <div className="slider-content left-text white-text">
            <h1>
              New Drop <br />
              Falak
            </h1>
            <button
              className="shop-now-btn"
              onClick={() => navigate(userRoutes.newArrivals)}
            >
              Shop Now
            </button>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default BannerSlider;
