import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Delete, Get, Post } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import {
  capitalizeFirstLetter,
  formatCurrency,
} from "../Supporting files/HelpingFunction";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "./../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";
import ProductsList from "../components/ProductsList";

const Wishlist = () => {
  const cartData = useSelector((state) => state.user.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  //Get all wishlist list
  const fetchWishList = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getWishlist}`);
      setWishlist(response.response.products);
      setIsLoader(false);
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      setWishlist([]);
      setIsLoader(false);
      console.error("Error ", error.message);
    }
  };

  const deleteWishList = async (productId, variantId, sizeId) => {
    let payload = {
      productId,
      variantId,
      sizeId,
    };

    try {
      const response = await Delete(`${URL_CONSTANTS.deleteWishlist}`, payload);
      fetchWishList();
      toast.success("Wishlist updated successfully");
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const addToCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      toast.success("Cart updated successfully");
      dispatch(updateCart(response.response.products));
    } catch (error) {
      console.error("Error ", error);
    }
  };
  useEffect(() => {
    fetchWishList();
  }, []);

  return (
    <>
      {isLoader && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="wishlistSection">
          <h2 className="theme_heading">Wishlist</h2>
          <Box>
            <Grid container columnSpacing={3} className="wish_box">
              {wishlist.map((data) => {
                let is_exist = cartData?.some(
                  (productData) => productData.sizeId === data.sizeId
                );
                let quantity = Number(
                  data?.productDetail?.variants?.sizes?.quantity
                );
                return (
                  <Grid xs={12} sm={6} md={4} lg={3} className="follow mb-3">
                    <img
                      loading="eager"
                      src={data?.productDetail?.variants.images[0]}
                      alt="insta"
                      className="follow-img"
                      onClick={() => {
                        navigate(
                          `${userRoutes.productDetail}?productId=${data.productId}`
                        );
                      }}
                    />
                    <Grid xs={12} className="text-center">
                      <div
                        className="fav"
                        onClick={() => {
                          deleteWishList(
                            data.productId,
                            data.variantId,
                            data.sizeId
                          );
                        }}
                      >
                        <img
                          loading="eager"
                          src="images/fav.svg"
                          alt="svg"
                          height={"20px"}
                          width={"22px"}
                        />
                      </div>
                      <div className="desc">
                        <p>
                          {capitalizeFirstLetter(data?.productDetail?.title)}
                        </p>
                        <p className="rate">
                          {formatCurrency(
                            data?.productDetail?.variants?.sizes?.price
                          )}
                        </p>
                        <Grid xs={12} className="ctaBtn mt-2 text-center">
                          <Button
                            variant="contained"
                            className="whiteBtn"
                            onClick={() => {
                              if (!is_exist && quantity > 0) {
                                addToCart(
                                  data.productId,
                                  data.variantId,
                                  data.sizeId
                                );
                              }
                            }}
                          >
                            {is_exist
                              ? "Added to cart"
                              : quantity < 1
                              ? "Out of stock"
                              : "Add to cart"}
                          </Button>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <ProductsList
          apiUrl={URL_CONSTANTS.getNewArrival}
          text1={"Our"}
          text2={"New Arrivals"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.newArrivals}
        />
        <ProductsList
          apiUrl={URL_CONSTANTS.getBestSeller}
          text1={"Our"}
          text2={"Top BestSeller"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.bestSeller}
        />

        <Footer />
      </Box>
    </>
  );
};

export default Wishlist;
