import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Delete, Get, Post, Put } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { formatCurrency } from "../Supporting files/HelpingFunction";
import { userRoutes } from "../Routes/UserRoutes";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";
import toast from "react-hot-toast";
import QuickView from "./QuickView";
import VisibilityIcon from "@mui/icons-material/Visibility";
const ProductsList = ({
  apiUrl,
  text1,
  text2,
  productToShow,
  buttonRedirectionUrl,
}) => {
  const wishlistData = useSelector((state) => state?.user?.wishlist);
  const cartData = useSelector((state) => state.user.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getAllProducts, setGetAllProducts] = useState([]);
  const [quickViewData, setquickViewData] = useState([]);
  const [selectedVarientsId, setselectedVarientsId] = useState("");
  const [selectedSizesId, setselectedSizesId] = useState("");
  useEffect(() => {
    GetAllProduct();
  }, []);

  //Get getAllProducts
  const GetAllProduct = async () => {
    try {
      const response = await Get(apiUrl);
      setGetAllProducts(response.response.products || response.response);
    } catch (error) {
      console.error("Error fetching getAllProducts:", error.message);
    }
  };

  const addToCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));

      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const removeFromCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.removeCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));
      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const deleteWishList = async (productId, variantId, sizeId) => {
    let payload = {
      productId,
      variantId,
      sizeId,
    };

    try {
      const response = await Delete(`${URL_CONSTANTS.deleteWishlist}`, payload);
      toast.success("Wishlist updated successfully");
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const addToWishlist = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddToWishlist}`,
        JSON.stringify(dto)
      );
      dispatch(updateWishlist(response.response.products));
      toast.success("Wishlist updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const [open, setOpen] = useState(false);
  return (
    <>
      <QuickView
        open={open}
        handleClose={() => setOpen(false)}
        productItem={quickViewData}
        selectedVarients={selectedVarientsId}
        selectedSizes={selectedSizesId}
      />

      <Box className="lookSection">
        <Box className="look">
          <h2
            className="theme_heading_xl pt-10 pb-10"
          >
            {text1} <span>{text2}</span>
          </h2>
          <Box>
            <Grid
              container
              className="look_container"
              columnSpacing={2}
              rowSpacing={4}
              justifyContent={"center"}
            >
              {getAllProducts?.slice(-productToShow || -10)?.map((product) => {
                let is_exist = cartData?.some(
                  (productData) =>
                    productData.sizeId === product?.variants[0]?.sizes[0]?._id
                );
                let is_WishlistExist = wishlistData?.some(
                  (productData) =>
                    productData.sizeId === product?.variants[0]?.sizes[0]?._id
                );
                return (
                  <Grid xs={6} sm={6} md={4} lg={3} className="lookImg">
                    <div className="actions">
                      <img
                        className="cart"
                        loading="eager"
                        src={
                          is_exist
                            ? "images/cart_full.png"
                            : "images/cartWhite.svg"
                        }
                        onClick={() => {
                          if (!is_exist) {
                            addToCart(
                              product._id,
                              product?.variants[0]?._id,
                              product?.variants[0]?.sizes[0]?._id
                            );
                          } else {
                            removeFromCart(
                              product._id,
                              product?.variants[0]?._id,
                              product?.variants[0]?.sizes[0]?._id
                            );
                          }
                        }}
                      />
                      <img
                        className="fav"
                        loading="eager"
                        src={
                          is_WishlistExist
                            ? "images/fav.svg"
                            : "images/favWhite.svg"
                        }
                        onClick={() => {
                          if (!is_WishlistExist) {
                            addToWishlist(
                              product._id,
                              product?.variants[0]?._id,
                              product?.variants[0]?.sizes[0]?._id
                            );
                          } else {
                            deleteWishList(
                              product._id,
                              product?.variants[0]?._id,
                              product?.variants[0]?.sizes[0]?._id
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="product-image-container">
                      <img
                        className="mainPic"
                        loading="eager"
                        src={product.variants[0].images[0]}
                        alt="look"
                        onClick={() => {
                          navigate(
                            `${userRoutes.productDetail}?productId=${product._id}`
                          );
                        }}
                      />

                      {/* Quick View Eye Button */}
                      <div
                        className="quick-view"
                        onClick={() => {
                          setselectedVarientsId(product?.variants[0]?._id);
                          setselectedSizesId(
                            product?.variants[0]?.sizes[0]?._id
                          );
                          setquickViewData(product);
                          setOpen(true);
                        }}
                      >
                        <VisibilityIcon sx={{ fontSize: 30 }} />
                        {/* <span className="quick-view-text">Quick View</span> */}
                      </div>
                    </div>
                    <div className="desc">
                      <p>{product.title}</p>
                      <p>{formatCurrency(product.variants[0].sizes[0].mrp)}</p>
                    </div>
                    <div className="size_buttons">
                      {["s", "m", "l", "xl", "xxl"].map((size, index) => {
                        // Normalize size to lowercase for comparison
                        const normalizedSize = size.toLowerCase();
                        // Get all sizes from product.variants and normalize them to lowercase
                        const availableSizes = new Set(
                          product.variants.flatMap((variant) =>
                            variant.sizes.map((sizeObj) =>
                              sizeObj.size.toLowerCase()
                            )
                          )
                        );

                        // Check if the current size exists in the available sizes
                        const isSelected = availableSizes.has(normalizedSize);
                        return (
                          <div
                            key={index}
                            className={`size_all ${
                              isSelected ? "selected" : ""
                            }`}
                          >
                            {size}
                          </div>
                        );
                      })}
                    </div>
                  </Grid>
                );
              })}

              <Grid xs={12} className="ctaBtn text-center my-6 ">
                <Button
                  onClick={() => {
                    navigate(buttonRedirectionUrl || userRoutes.products);
                  }}
                  variant="contained"
                  className="submitBtn"
                >
                  View All
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductsList;
