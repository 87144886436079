import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { adminRoutes } from "../Routes/AdminRoutes";
import Cookies from "js-cookie";

const NavigationMenu = () => {
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState({
    product: null,
    category: null,
    blog: null,
    user: null,
    order: null,
  });

  const handleMenuClick = (event, menuType) => {
    setMenuAnchorEl((prev) => ({ ...prev, [menuType]: event.currentTarget }));
  };

  const handleMenuClose = (menuType) => {
    setMenuAnchorEl((prev) => ({ ...prev, [menuType]: null }));
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, cursor: "pointer" }}
          onClick={() => navigate(adminRoutes.homePage)}
        >
          Ishnoor Admin Panel
        </Typography>

        <Button color="inherit" onClick={(e) => handleMenuClick(e, "product")}>
          Products
        </Button>
        <Menu
          anchorEl={menuAnchorEl.product}
          open={Boolean(menuAnchorEl.product)}
          onClose={() => handleMenuClose("product")}
        >
          <MenuItem
            component={Link}
            to={adminRoutes.addProduct}
            onClick={() => handleMenuClose("product")}
          >
            Add Product
          </MenuItem>
          <MenuItem
            component={Link}
            to={adminRoutes.allProducts}
            onClick={() => handleMenuClose("product")}
          >
            All Products
          </MenuItem>
        </Menu>

        <Button color="inherit" onClick={(e) => handleMenuClick(e, "category")}>
          Categories
        </Button>
        <Menu
          anchorEl={menuAnchorEl.category}
          open={Boolean(menuAnchorEl.category)}
          onClose={() => handleMenuClose("category")}
        >
          <MenuItem
            component={Link}
            to={adminRoutes.addCategory}
            onClick={() => handleMenuClose("category")}
          >
            Add Category
          </MenuItem>
          <MenuItem
            component={Link}
            to={adminRoutes.allCategories}
            onClick={() => handleMenuClose("category")}
          >
            All Categories
          </MenuItem>
        </Menu>

        <Button color="inherit" onClick={(e) => handleMenuClick(e, "blog")}>
          Blogs
        </Button>
        <Menu
          anchorEl={menuAnchorEl.blog}
          open={Boolean(menuAnchorEl.blog)}
          onClose={() => handleMenuClose("blog")}
        >
          <MenuItem
            component={Link}
            to={adminRoutes.createBlog}
            onClick={() => handleMenuClose("blog")}
          >
            Add Blog
          </MenuItem>
          <MenuItem
            component={Link}
            to={adminRoutes.allBlogs}
            onClick={() => handleMenuClose("blog")}
          >
            All Blogs
          </MenuItem>
        </Menu>

        <Button color="inherit" onClick={(e) => handleMenuClick(e, "user")}>
          Users
        </Button>
        <Menu
          anchorEl={menuAnchorEl.user}
          open={Boolean(menuAnchorEl.user)}
          onClose={() => handleMenuClose("user")}
        >
          <MenuItem
            component={Link}
            to={adminRoutes.allUsers}
            onClick={() => handleMenuClose("user")}
          >
            All Users
          </MenuItem>
          {/* Add more User related menu items here */}
        </Menu>

        <Button color="inherit" onClick={(e) => handleMenuClick(e, "contact")}>
          Contact
        </Button>
        <Menu
          anchorEl={menuAnchorEl.contact}
          open={Boolean(menuAnchorEl.contact)}
          onClose={() => handleMenuClose("contact")}
        >
          <MenuItem
            component={Link}
            to={adminRoutes.allContact}
            onClick={() => handleMenuClose("contact")}
          >
            All Contact
          </MenuItem>
          {/* Add more User related menu items here */}
        </Menu>
        <Button color="inherit" onClick={(e) => handleMenuClick(e, "order")}>
          Orders
        </Button>
        <Menu
          anchorEl={menuAnchorEl.order}
          open={Boolean(menuAnchorEl.order)}
          onClose={() => handleMenuClose("order")}
        >
          <MenuItem
            component={Link}
            to={adminRoutes.allOrders}
            onClick={() => handleMenuClose("order")}
          >
            All Orders
          </MenuItem>
          {/* Add more Order related menu items here */}
        </Menu>
        <Button
          color="inherit"
          onClick={() => {
            Cookies.remove("ishnooradminToken");
            window.location.href = "/admin/login";
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationMenu;
