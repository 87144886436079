import React, { useEffect, useState } from "react";
import {
  Modal,
  Fade,
  Typography,
  Button,
  IconButton,
  Backdrop,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatCurrency } from "../Supporting files/HelpingFunction";
import Grid from "@mui/material/Unstable_Grid2";

const QuickView = ({
  open,
  handleClose,
  productItem,
  selectedVarients,
  selectedSizes,
}) => {
  const [selectedSize, setSelectedSize] = useState("S");
  const [selectedVarientsId, setselectedVarientsId] = useState("");
  const [selectedSizesId, setselectedSizesId] = useState("");
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
  };
 
  useEffect(() => {
    if (open) {
      setselectedVarientsId(selectedVarients);
      setselectedSizesId(selectedSizes);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in={open}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh", padding: 0 }}
        >
          <Grid
            xs={12}
            md={8}
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              overflow: "hidden",
              display: "flex",
              height: "95vh", // Increased height for the popup
              position: "relative",
            }}
          >
            <Grid lg={7} sx={{ padding: 0 }}>
              {/* Product Image Slider */}
              <Slider {...sliderSettings}>
                {productItem?.variants
                  ?.find((variant) => variant._id === selectedVarientsId)
                  ?.images?.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <img
                        src={image}
                        alt={productItem?.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ))}
              </Slider>
            </Grid>
            <Grid
              lg={5}
              sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start", // Align content to the top
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", top: 8, right: 8 }}
              >
                <CloseIcon />
              </IconButton>

              {/* Product Details */}
              <Typography variant="h5" sx={{ mt: 2 }}>
                {productItem?.title}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ textDecoration: "line-through" }}
              >
                {formatCurrency(
                  productItem?.variants
                    ?.find((variant) => variant._id === selectedVarientsId)
                    ?.sizes.find((size) => size._id === selectedSizesId)?.mrp
                )}
              </Typography>
              <Typography variant="h6" color="primary">
                {formatCurrency(
                  productItem?.variants
                    ?.find((variant) => variant._id === selectedVarientsId)
                    ?.sizes.find((size) => size._id === selectedSizesId)
                    ?.price ?? 0
                )}
              </Typography>

              <Typography variant="body2" sx={{ mt: 2 }}>
                {productItem?.description}
              </Typography>
       
              <div className="colorss mb-6">
                <h6>Select Color :</h6>
                {productItem?.variants?.map((variant) => {
                  return (
                    <div
                      onClick={() => {
                        setselectedVarientsId(variant?._id);
                        setselectedSizesId(variant?.sizes[0]?._id);
                      }}
                      className={`color_all ${
                        variant._id === selectedVarientsId ? "selected" : ""
                      }`}
                      style={{ background: variant?.color,height: "12px" }}
                    ></div>
                  );
                })}
              </div>
              <div className="sizes mb-6">
                <h6>Size :</h6>
                <>
                  {productItem?.variants
                    ?.find((variant) => variant._id === selectedVarientsId)
                    ?.sizes?.map((size) => {
                      return (
                        <div
                          onClick={() => {
                            setselectedSizesId(size._id);
                          }}
                          key={size._id}
                          className={`size_all ${
                            selectedSizesId === size._id ? "selected" : ""
                          }`}
                        >
                          {size.size}
                        </div>
                      );
                    })}
                </>
              </div>
              {/* Add to Cart Button */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Add to Cart
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

// Custom Arrow Component
const CustomArrow = ({ direction, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 10,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        padding: "10px",
        color: "white",
        cursor: "pointer",
        [direction === "next" ? "right" : "left"]: "15px",
      }}
    >
      {direction === "next" ? "→" : "←"}
    </div>
  );
};

export default QuickView;
