import { Box, Menu } from "@mui/material";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../commonSCSS/header.scss";
import { logoutUser } from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import { capitalizeFirstLetter } from "../Supporting files/HelpingFunction";
import SearchBar from "./Modals/SearchBar";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const cartData = useSelector((state) => state.user.cart);
  const { token, userDetail } = useSelector((state) => state.user.userInfo);
  const [profileDrop, setProfileDrop] = useState(null);
  const openProfile = Boolean(profileDrop);
  const [userDropdown, setUserDropdown] = useState(null);
  const [searchDrop, setSearchDrop] = useState(false);

  const openProfileDrop = (event) => {
    setProfileDrop(event.currentTarget);
  };
  const closeProfileDrop = () => {
    setProfileDrop(null);
  };
  const handleClick = (event) => {
    setUserDropdown(event.currentTarget);
  };
  const handleClose = () => {
    setUserDropdown(null);
  };

  return (
    <>
      <SearchBar open={searchDrop} setOpen={setSearchDrop} />
      <Box className="userHeader">
        <Box alignItems="center" className="headContainer pt-3 pb-3">
          <Box className="header_grid">
            <div
              className="p_black df"
              onClick={openProfileDrop}
              size="large"
              aria-controls={openProfile ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProfile ? "true" : undefined}
            >
              <img
                loading="eager"
                src="/images/userLogin.svg"
                alt="login"
                className="mr-2"
              />
              <p className="p_black">Profile</p>
            </div>
            <Menu
              className="profileDropMenu"
              anchorEl={profileDrop}
              id="account-menu"
              open={openProfile}
              onClose={closeProfileDrop}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
                  borderRadius: "8px",
                  mt: 1,
                  "& .MuiAvatar-root": {
                    width: 22,
                    height: 22,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div className="profile_drop_content">
                {token && Cookies.get("ishnoorauthToken") ? (
                  <>
                    <p className="wc mb-3">{`Welcome ${capitalizeFirstLetter(
                      userDetail?.firstName
                    )}`}</p>
                    <p className="common_page sub mb-5">{userDetail?.email}</p>
                    <p
                      className="common_page mb-5"
                      onClick={() => navigate(userRoutes.orders)}
                    >
                      Order
                    </p>
                    <p
                      className="common_page mb-5"
                      onClick={() => navigate(userRoutes.wishlist)}
                    >
                      {" "}
                      Wishlist
                    </p>
                    <p
                      className="common_page mb-3"
                      onClick={() => navigate(userRoutes.contactUs)}
                    >
                      Contact Us
                    </p>
                    <hr className="hrTag mb-3"></hr>
                    {/* <p className="common_page mb-5">Edit Profile</p> */}
                    <p
                      className="common_page logout"
                      onClick={() => {
                        closeProfileDrop();
                        dispatch(logoutUser());
                        navigate(userRoutes.dashboard);
                      }}
                    >
                      Logout
                    </p>
                  </>
                ) : (
                  <>
                    <p className="wc mb-3">Welcome</p>
                    <p className="common_page sub mb-5">
                      To access account and manage order
                    </p>
                    <div className="acn_btn df mb-5">
                      <p
                        className="login mr-5"
                        onClick={() => navigate(userRoutes.login)}
                      >
                        Login
                      </p>
                      <p
                        className="login"
                        onClick={() => navigate(userRoutes.signUp)}
                      >
                        Signup
                      </p>
                    </div>
                    <p
                      className="common_page mb-5"
                      onClick={() => navigate(userRoutes.orders)}
                    >
                      Order
                    </p>
                    <p
                      className="common_page mb-5"
                      onClick={() => navigate(userRoutes.wishlist)}
                    >
                      Wishlist
                    </p>
                    <p
                      className="common_page"
                      onClick={() => navigate(userRoutes.contactUs)}
                    >
                      Contact Us
                    </p>
                  </>
                )}
              </div>
            </Menu>
          </Box>
          <div className="header_grid" onClick={() => setSearchDrop(true)}>
            <img
              loading="eager"
              src="/images/search.svg"
              alt="login"
              height={25}
              width={25}
            />
          </div>
          <div className="header_grid sale">
            <p className="p_red">
              MID-SEASON SALE UP TO 70% OFF. USE CODE: “SALE70”. Shop Now
            </p>
          </div>
          <div
            className="header_grid"
            onClick={() => navigate(userRoutes.wishlist)}
          >
            <img
              loading="eager"
              src="/images/fav.svg"
              alt="wishlist"
              className="mr-2"
            />
            <p className="p_black">WishList</p>
          </div>
          <div
            className="header_grid"
            onClick={() => navigate(userRoutes.cart)}
            style={{ position: "relative" }}
          >
            <img
              loading="eager"
              src="/images/cart.png"
              alt="cart"
              className="mr-3 cart-icon"
            />
            <p className="p_black">Cart</p>
            {/* Badge for total number of products */}
            <div className="cart-badge">{cartData?.length || 0}</div>{" "}
            {/* Static number */}
          </div>
        </Box>
        {!isHomePage && (
          <>
            <Box>
              <hr className="hrTag pb-3"></hr>
            </Box>
            <Box className="headBottom">
              <div className="logo_section">
                <div onClick={() => navigate(userRoutes.dashboard)}>
                  <img style={{width:"150px"}} loading="eager" src="/images/logo.png" alt="logo" />
                </div>
              </div>
              <div className="right_section">
                <div onClick={() => navigate(userRoutes.newArrivals)}>
                  <p className="p_black">New Arrivals</p>
                </div>
                <div onClick={() => navigate(userRoutes.featuredProduts)}>
                  <p className="p_black">Featured Products</p>
                </div>
                <div onClick={() => navigate(userRoutes.bestSeller)}>
                  <p className="p_black">Best Seller</p>
                </div>
              </div>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Header;
