import { RemoveCircle } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import ImageUploader from "../components/ImageUploader";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
import toast from "react-hot-toast";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Post } from "../Api/api";
import RichTextEditor from "../components/RichTextEditor";

const AddProducts = () => {
    const [editorData, setEditorData] = useState("<b>write product description here</b>");
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    categories: [],
    brand: "",
    isFeatured: false,
    status: 1,
    variants: [
      {
        color: "",
        images: [],
        sizes: [
          {
            size: "",
            price: "",
            dimensions: "",
            quantity: "",
            mrp: "",
            discount: "",
          },
        ],
      },
    ],
  });
console.log("dfgdfgdfgdf",formValues.description)
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [colorPickerVisible, setColorPickerVisible] = useState(null);
  useEffect(() => {
    axios
      .get(ADMINURL_CONSTANTS.GetAllCategory)
      .then((response) => {
        const categories = response.data.response.map((category) => ({
          _id: category._id,
          title: category.title,
        }));
        setCategoryOptions(categories);
      })
      .catch((error) => {
        console.error("Error fetching categories", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleCategoryChange = (event, newValue) => {
    setFormValues({
      ...formValues,
      categories: newValue,
    });
  };

  const handleVariantChange = (index, e) => {
    const { name, value } = e.target;
    const variants = [...formValues.variants];
    variants[index][name] = value;
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const handleColorChange = (index, color) => {
    const variants = [...formValues.variants];
    variants[index].color = color.hex;
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const addVariant = () => {
    setFormValues({
      ...formValues,
      variants: [
        ...formValues.variants,
        {
          color: "",
          images: [],
          sizes: [
            {
              size: "",
              price: "",
              dimensions: "",
              quantity: "",
              mrp: "",
              discount: "",
            },
          ],
        },
      ],
    });
  };

  const removeVariant = (index) => {
    const variants = [...formValues.variants];
    variants.splice(index, 1);
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const addSize = (variantIndex) => {
    const variants = [...formValues.variants];
    variants[variantIndex].sizes.push({
      size: "",
      price: "",
      dimensions: "",
      quantity: "",
      mrp: "",
      discount: "",
    });
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const removeSize = (variantIndex, sizeIndex) => {
    const variants = [...formValues.variants];
    variants[variantIndex].sizes.splice(sizeIndex, 1);
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const handleSizeChange = (variantIndex, sizeIndex, e) => {
    const { name, value } = e.target;
    const variants = [...formValues.variants];
    variants[variantIndex].sizes[sizeIndex][name] = value;
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const handleImageUploadSuccess = (variantIndex, uploadedUrls) => {
    const variants = [...formValues.variants];
    variants[variantIndex].images = uploadedUrls;
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return toast.error("Please Fill Mandatory Fields");
    }
    try {
      const payload = {
        ...formValues,
        variants: formValues.variants.map((variant) => ({
          ...variant,
          images: variant.images,
        })),
      };

      await Post(ADMINURL_CONSTANTS.AddProduct, payload);
      alert("Product added successfully");
      setFormValues({
        title: "",
        description: "",
        categories: [],
        brand: "",
        status: 1,
        variants: [
          {
            color: "",
            images: [],
            sizes: [
              {
                size: "",
                price: "",
                dimensions: "",
                quantity: "",
                mrp: "",
                discount: "",
              },
            ],
          },
        ],
      });
    } catch (error) {
      console.error("Submission error", error);
    }
  };
  const validateForm = () => {
    let valid = true;

    formValues.variants.forEach((variant, variantIndex) => {
      if (!variant.color.trim()) {
        toast.error(`Variant ${variantIndex + 1}: Color is required`);
        valid = false;
      }
      if (!variant.images.length) {
        toast.error(`Variant ${variantIndex + 1}: Images are required`);
        valid = false;
      }
      variant.sizes.forEach((size, sizeIndex) => {
        if (!size.size.trim()) {
          toast.error(
            `Variant ${variantIndex + 1}, Size ${
              sizeIndex + 1
            }: Size is required`
          );
          valid = false;
        }
        if (!size.price.trim()) {
          toast.error(
            `Variant ${variantIndex + 1}, Size ${
              sizeIndex + 1
            }: Price is required`
          );
          valid = false;
        }
        if (!size.quantity.trim()) {
          toast.error(
            `Variant ${variantIndex + 1}, Size ${
              sizeIndex + 1
            }: Quantity is required`
          );
          valid = false;
        }
        if (!size.mrp.trim()) {
          toast.error(
            `Variant ${variantIndex + 1}, Size ${
              sizeIndex + 1
            }: MRP is required`
          );
          valid = false;
        }
      });
    });

    return valid;
  };
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add New Product
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Product Title"
                name="title"
                value={formValues.title}
                onChange={handleChange}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <RichTextEditor
                content={editorData}
                onChange={(data) => {
                  setFormValues({ ...formValues, description: data });
                  setEditorData(data);
                }}
              />
              {/* <TextField
                fullWidth
                label="Description"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                margin="normal"
                required
              /> */}
              <div dangerouslySetInnerHTML={{ __html: formValues.description }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                options={categoryOptions}
                getOptionLabel={(option) => option.title}
                value={formValues.categories}
                onChange={handleCategoryChange}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    placeholder="Select categories"
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Brand"
                name="brand"
                value={formValues.brand}
                onChange={handleChange}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Status"
                name="status"
                type="number"
                value={formValues.status}
                onChange={handleChange}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.isFeatured}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        isFeatured: e.target.checked,
                      });
                    }}
                    name="isFeatured"
                    color="primary" // Change color as needed
                  />
                }
                label="Is this product Featured ?"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Variants</Typography>
              {formValues.variants.map((variant, variantIndex) => (
                <Box key={variantIndex} sx={{ mt: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setColorPickerVisible(variantIndex);
                        }}
                        sx={{ marginRight: 2 }}
                      >
                        Pick Color
                      </Button>
                      <div
                        style={{
                          width: "36px",
                          height: "14px",
                          borderRadius: "2px",
                          backgroundColor: variant.color,
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      />
                    </div>
                    <IconButton
                      color="secondary"
                      onClick={() => removeVariant(variantIndex)}
                    >
                      <RemoveCircle />
                    </IconButton>
                  </Box>
                  {colorPickerVisible === variantIndex && (
                    <SketchPicker
                      color={variant.color}
                      onChangeComplete={(color) =>
                        handleColorChange(variantIndex, color)
                      }
                      sx={{ mb: 2 }}
                    />
                  )}
                  <ImageUploader
                    onUploadSuccess={(uploadedUrls) =>
                      handleImageUploadSuccess(variantIndex, uploadedUrls)
                    }
                  />
                  <Typography variant="subtitle1">Sizes</Typography>
                  {variant.sizes.map((size, sizeIndex) => (
                    <Box
                      key={sizeIndex}
                      sx={{ display: "flex", alignItems: "center", mb: 2 }}
                    >
                      <TextField
                        label="Size"
                        name="size"
                        value={size.size}
                        onChange={(e) =>
                          handleSizeChange(variantIndex, sizeIndex, e)
                        }
                        margin="normal"
                        required
                        sx={{ mr: 2 }}
                      />
                      <TextField
                        label="Dimensions"
                        name="dimensions"
                        value={size.dimensions}
                        onChange={(e) =>
                          handleSizeChange(variantIndex, sizeIndex, e)
                        }
                        margin="normal"
                        sx={{ mr: 2 }}
                      />
                      <TextField
                        label="Quantity"
                        name="quantity"
                        type="number"
                        value={size.quantity}
                        onChange={(e) =>
                          handleSizeChange(variantIndex, sizeIndex, e)
                        }
                        margin="normal"
                        required
                        sx={{ mr: 2 }}
                      />
                      <TextField
                        label="MRP"
                        name="mrp"
                        type="number"
                        value={size.mrp}
                        onChange={(e) =>
                          handleSizeChange(variantIndex, sizeIndex, e)
                        }
                        margin="normal"
                        required
                        sx={{ mr: 2 }}
                      />
                      <TextField
                        label="Price"
                        name="price"
                        type="number"
                        value={size.price}
                        onChange={(e) =>
                          handleSizeChange(variantIndex, sizeIndex, e)
                        }
                        margin="normal"
                        required
                        sx={{ mr: 2 }}
                      />
                      <TextField
                        label="Discount"
                        name="discount"
                        type="number"
                        value={size.discount}
                        onChange={(e) =>
                          handleSizeChange(variantIndex, sizeIndex, e)
                        }
                        margin="normal"
                        required
                        sx={{ mr: 2 }}
                      />
                      <IconButton
                        color="secondary"
                        onClick={() => removeSize(variantIndex, sizeIndex)}
                      >
                        <RemoveCircle />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    onClick={() => addSize(variantIndex)}
                    sx={{ mt: 2 }}
                  >
                    Add Size
                  </Button>
                </Box>
              ))}
              <Button variant="outlined" onClick={addVariant} sx={{ mt: 2 }}>
                Add Variant
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default AddProducts;
