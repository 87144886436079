import { Box, Button, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../commonSCSS/modals.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // Import success icon
import { userRoutes } from "../../Routes/UserRoutes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const PasswordSuccess = ({ pwSuccess, message }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(pwSuccess);
  return (
    <>
      <Modal open={open} className="modalWrapper">
        <Box className="modalInner p-0" maxWidth={435}>
          <Grid container textAlign={"center"} className="px-9 py-6">
            <Grid xs={12} className="title mb-12">
              <img loading="eager" src="/images/pwSuccess.png" alt="l" />
            </Grid>
            <Grid xs={12}>
              <h1 className="success">Success</h1>
            </Grid>
            <Grid xs={12}>
              <p className="msg">{message}</p>
            </Grid>
            {/* <Grid xs={12} className="loading">
                <CheckCircleOutlineIcon disableShrink className="loader" />
              </Grid> */}
          </Grid>
          <div className="ctaBtn">
            <div className="success">
              <Button
                onClick={() => {
                  setOpen(false);
                  navigate(userRoutes.orders);
                }}
                variant="contained"
                className="mdBtn"
              >
                Okay
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PasswordSuccess;
