import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Delete, Get, Post, Put } from "../Api/api";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";

const NewArrivals = () => {
  const wishlistData = useSelector((state) => state?.user?.wishlist);
  const cartData = useSelector((state) => state.user.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [activeCat, setActiveCat] = useState(null);
  const [newArrivals, setNewArrivals] = useState([]);
  const [loader, setloader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef();

  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  //Get new arrival
  const getNewArrival = async (id, pageNum = 1) => {
    try {
      const response = await Get(
        `${URL_CONSTANTS.getNewArrival}?categoryId=${id ?? ""
        }&page=${page}&pageSize=${pageSize}`
      );
      setloader(false);

      if (response.response.length > 0) {
        setNewArrivals((prevArrivals) => [
          ...prevArrivals,
          ...response.response,
        ]);
        const totalLoaded = (pageNum - 1) * pageSize + response.response.length;
        setHasMore(totalLoaded < response.count);
        setLoadingMore(false);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };
  const lastProductRef = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoadingMore(true);
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, hasMore]
  );

  useEffect(() => {
    if (page > 1) {
      getNewArrival(activeCat ?? "", page, pageSize); // Fetch the next page
    }
  }, [page]);
  useEffect(() => {
    fetchCategories();
    getNewArrival();
  }, []);
  const addToCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));

      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const removeFromCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.removeCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));
      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const deleteWishList = async (productId, variantId, sizeId) => {
    let payload = {
      productId,
      variantId,
      sizeId,
    };

    try {
      const response = await Delete(`${URL_CONSTANTS.deleteWishlist}`, payload);
      toast.success("Wishlist updated successfully");
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const addToWishlist = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddToWishlist}`,
        JSON.stringify(dto)
      );
      dispatch(updateWishlist(response.response.products));
      toast.success("Wishlist updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  return (
    <>
      {loader && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="shopSection">
          <Box className="shop_box">
            <h2 className="theme_heading">
              New <span>Arrivals</span>
            </h2>
            <Box className="arrivall">
              <Grid container spacing={3} className="category_container">
                <Grid xs={12}>
                  <div className="cat_scroller">
                    {categories?.length > 0 &&
                      categories?.map((categoryData, i) => (
                        <div
                          className="category pb-0"
                          key={categoryData?._id}
                          onClick={() => {
                            setPage(1);
                            setNewArrivals([]);
                            getNewArrival(categoryData._id);
                          }}
                        >
                          <img
                            loading="eager"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = "images/dummy_category.jpg";
                            }}
                            className={`${activeCat === categoryData._id ? "active" : ""
                              }`}
                            onClick={() => {
                              setActiveCat(categoryData._id);
                            }}
                            src={
                              categoryData.image || "images/dummy_category.jpg"
                            }
                            alt={categoryData.title}
                          />
                          <p>{categoryData.title}</p>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box className="style arrival mb-8">
              <Grid
                container
                columnSpacing={2}
                rowSpacing={1}
                justifyContent={"center"}
              >
                {newArrivals?.length > 0 &&
                  newArrivals?.map((item, index) => {
                    let is_exist = cartData?.some(
                      (productData) =>
                        productData.sizeId ===
                        item?.variants[0]?.sizes[0]?._id
                    );
                    let is_WishlistExist = wishlistData?.some(
                      (productData) =>
                        productData.sizeId ===
                        item?.variants[0]?.sizes[0]?._id
                    );
                    return (
                      <Grid
                        xs={6}
                        sm={6}
                        md={4}
                        lg={3}
                        className="style_slider"
                      >
                        <div className="actions">
                          <img
                            className="cart"
                            loading="eager"
                            src={
                              is_exist
                                ? "images/cart_full.png"
                                : "images/cartWhite.svg"
                            }
                            onClick={() => {
                              if (!is_exist) {
                                addToCart(
                                  item._id,
                                  item?.variants[0]?._id,
                                  item?.variants[0]?.sizes[0]?._id
                                );
                              } else {
                                removeFromCart(
                                  item._id,
                                  item?.variants[0]?._id,
                                  item?.variants[0]?.sizes[0]?._id
                                );
                              }
                            }}
                          />
                          <img
                            className="fav"
                            loading="eager"
                            src={
                              is_WishlistExist
                                ? "images/fav.svg"
                                : "images/favWhite.svg"
                            }
                            onClick={() => {
                              if (!is_WishlistExist) {
                                addToWishlist(
                                  item._id,
                                  item?.variants[0]?._id,
                                  item?.variants[0]?.sizes[0]?._id
                                );
                              } else {
                                deleteWishList(
                                  item._id,
                                  item?.variants[0]?._id,
                                  item?.variants[0]?.sizes[0]?._id
                                );
                              }
                            }}
                          />
                        </div>
                        <img
                          loading="eager"
                          className="arrival_img"
                          src={item?.variants[0]?.images[0]}
                          onClick={() => {
                            navigate(
                              `${userRoutes.productDetail}?productId=${item._id}`
                            );
                          }}
                        />
                        <div className="size_buttons">
                          {["s", "m", "l", "xl", "xxl"].map((size, index) => {
                            const normalizedSize = size.toLowerCase();
                            const availableSizes = new Set(
                              item.variants.flatMap((variant) =>
                                variant.sizes.map((sizeObj) =>
                                  sizeObj.size.toLowerCase()
                                )
                              )
                            );
                            const isSelected = availableSizes.has(normalizedSize);
                            return (
                              <div
                                key={index}
                                className={`size_all ${isSelected ? "selected" : ""}`}
                              >
                                {size}
                              </div>
                            );
                          })}
                        </div>
                      </Grid>
                    );
                  })}
                {/* Ref for the last product to trigger more load */}
                <div ref={lastProductRef} style={{ height: "20px" }} />
              </Grid>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};
export default NewArrivals;
