import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Check from "@mui/icons-material/Check";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import Stepper from "@mui/joy/Stepper";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Get } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";
import ReviewModal from "../components/Modals/ReviewModal";
import { userRoutes } from "../Routes/UserRoutes";
import { truncateDescription } from "../Supporting files/HelpingFunction";
import { updateReturnOrderNumber } from "../Redux/Slice/UserSlice";
import { useDispatch } from "react-redux";

const OrderDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const steps = [
    { name: "Order placed", img: "images/Package.png", value: "order_placed" },
    { name: "Packaging", img: "images/Notebook.png", value: "packaging" },
    { name: "On The Road", img: "images/Package.png", value: "on_the_road" },
    { name: "Delivered", img: "images/Notebook.png", value: "delivered" },
  ];
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [orderDetails, setorderDetails] = useState([]);
  const [reviewModal, setReviewModal] = useState(false);
  const [selectedProduct, setselectedProduct] = useState("");
  const [editReviewData, seteditReviewData] = useState({});
  const [isEdit, setisEdit] = useState(false);

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.OrdersDetails}${id}`);
      setorderDetails(response.response);
      const valueToFind = response?.response?.deliveryStatus;
      const index = steps.findIndex((step) => step.value === valueToFind);
      setActiveStep(index + 1);
      setLoading(false);
    } catch (error) {
      console.error("Error ", error.message);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      {reviewModal && (
        <ReviewModal
          open={reviewModal}
          onClose={() => setReviewModal(false)}
          orderData={orderDetails}
          selectedProduct={selectedProduct}
          isEdit={isEdit}
          editReviewData={editReviewData}
          onSuccess={getOrderDetails}
        />
      )}
      <Box className="mainWrapper">
        <Header />
        <Box className="orderDetailSection">
          <Grid container padding={0}>
            <Grid xs={12}>
              <div className="title">
                <ArrowBackIcon
                  onClick={() => {
                    navigate(userRoutes.orders);
                  }}
                />
                <h2>Order Details</h2>
              </div>
            </Grid>
            <Grid xs={12} className="pro_detail mt-12">
              <div>
                <h3>#{orderDetails.orderNumber}</h3>
                <h3 className="des my-2">
                  {orderDetails?.products?.length > 0 &&
                    orderDetails?.products[0]?.productDetail?.title}
                </h3>
                <h3 className="des_light">
                  {orderDetails?.products?.length} Products
                  <span className="mx-2">•</span>
                  {steps[activeStep - 1]?.name} in{" "}
                  {moment(new Date(orderDetails?.createdAt)).format(
                    "DD MMM, YYYY [at] h:mm A"
                  )}
                </h3>
              </div>
              <div>
                <h1>₹ {orderDetails?.totalAmount}</h1>
              </div>
            </Grid>
            <Grid xs={12} className="expected mt-6">
              <p className="date mb-6">
                Order expected arrival{" "}
                {moment(orderDetails?.createdAt)
                  .add(7, "days")
                  .format("DD MMM, YYYY")}
              </p>
              <div class="stepper_section">
                <Stepper sx={{ width: "100%" }}>
                  {steps?.map((step, i) => (
                    <Step
                      className={`${
                        activeStep >= i + 1 ? "active_step" : "inactive_step"
                      }`}
                      orientation="vertical"
                      indicator={
                        <StepIndicator variant="solid" color="neutral">
                          {activeStep >= i + 1 ? <Check /> : ""}
                        </StepIndicator>
                      }
                    >
                      <img
                        loading="eager"
                        className="mb-1"
                        src={step.img}
                        alt="ordr"
                      />
                      {step.name}
                    </Step>
                  ))}
                </Stepper>
              </div>
            </Grid>
            <Grid xs={12} className="product_table mt-12">
              <p className="count mb-5">
                Product ({orderDetails?.products?.length})
              </p>
              <ul className="ul_header">
                <li>PRODUCTS</li>
                <li>Size</li>
                <li>PRICE</li>
                <li>QUANTITY</li>
                <li>SUB-TOTAL</li>
              </ul>
              {orderDetails?.products?.length > 0 &&
                orderDetails?.products?.map((product, index) => {
                  return (
                    <>
                      <ul className="ul_body">
                        <li style={{ cursor: "pointer" }}>
                          <img
                            loading="eager"
                            src={product?.productDetail?.variants?.images[0]}
                            alt="ii"
                            width={60}
                            onClick={() => {
                              navigate(
                                `${userRoutes.productDetail}?productId=${product?.productId}`
                              );
                            }}
                          />
                          <div className="body_div">
                            <h4 className="pro_title mb-1">
                              {product?.productDetail?.title}
                            </h4>
                            <h5 className="descr">
                              {truncateDescription(
                                product?.productDetail?.description,
                                70
                              )}
                            </h5>
                            <h4
                              onClick={() => {
                                setReviewModal(true);
                                setselectedProduct(product);
                                if (product?.review?.comment) {
                                  seteditReviewData(product.review);
                                  setisEdit(true);
                                }
                              }}
                              className="pro_title mb-1 mt-3"
                              style={{ cursor: "pointer" }}
                            >
                              {product?.review?.comment
                                ? "Edit Review"
                                : "Write Review"}
                            </h4>

                            <h4
                              onClick={() => {
                                dispatch(
                                  updateReturnOrderNumber({
                                    orderNumber: orderDetails.orderNumber,
                                    productId: product.productId,
                                    _id: product._id,
                                    name: product.productDetail.title,
                                    orderId: orderDetails._id,
                                  })
                                );
                                navigate(userRoutes.returnRequest);
                              }}
                              className="pro_title mb-1 mt-3"
                              style={{ cursor: "pointer" }}
                            >
                              {"Raise return request"}
                            </h4>
                          </div>
                        </li>
                        <li>{product?.productDetail?.variants?.sizes?.size}</li>
                        <li>₹ {product?.price}</li>
                        <li>{product?.quantity}X</li>
                        <li>₹ {product?.quantity * product?.price}</li>
                      </ul>
                      <div className="seprate"></div>
                    </>
                  );
                })}
            </Grid>
            <Grid xs={12} className="shipping mt-14">
              <Box className="w-100">
                <Grid container>
                  <Grid lg={3} className="ship">
                    <p className="line1 mb-3">Billing Address</p>
                    <p className="line2 mb-2">
                      {orderDetails?.deliveryAddress?.name}
                    </p>
                    <p className="line2 mb-3">
                      {orderDetails?.deliveryAddress?.address} ,{" "}
                      {orderDetails?.deliveryAddress?.city} ,{" "}
                      {orderDetails?.deliveryAddress?.district},{" "}
                      {orderDetails?.deliveryAddress?.pincode}
                    </p>
                    <p className="line2 mb-3">
                      Phone Number: {orderDetails?.deliveryAddress?.phone}
                    </p>
                    <p className="line2">Email: kevin.gilbert@gmail.com</p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default OrderDetail;
