import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import close icon
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ProductImageViewer = ({ imageUrls, onClose }) => {
  // Transforming the URLs into the format required by ImageGallery
  const images = imageUrls.map((url) => ({
    original: url,
    thumbnail: url, // Example of modifying the URL for thumbnails
  }));

  return (
    <Box sx={modalContentStyles}>
      {/* Close button */}
      <IconButton sx={closeButtonStyles} onClick={onClose} aria-label="close">
        <CloseIcon />
      </IconButton>

      <ImageGallery
        items={images}
        showThumbnails={true}
        showFullscreenButton={true}
        showPlayButton={false}
        lazyLoad={true}
        // Custom styles for the navigation arrows
        renderLeftNav={(onClick, isDisabled) => (
          <button
            className="image-gallery-left-nav"
            onClick={onClick}
            disabled={isDisabled}
            style={{ ...arrowButtonStyles, left: "30px" }} // Position left arrow
          >
            <span style={arrowIconStyles}>❮</span> {/* Left Arrow Icon */}
          </button>
        )}
        renderRightNav={(onClick, isDisabled) => (
          <button
            className="image-gallery-right-nav"
            onClick={onClick}
            disabled={isDisabled}
            style={{ ...arrowButtonStyles, right: "30px" }} // Position right arrow
          >
            <span style={arrowIconStyles}>❯</span> {/* Right Arrow Icon */}
          </button>
        )}
      />
    </Box>
  );
};

// Styles
const modalContentStyles = {
  position: "fixed", // Use 'fixed' or 'absolute'
  top: 0, // Align to the top of the viewport
  left: 0, // Align to the left of the viewport
  width: "100%", // Full width
  height: "100%", // Full height
  zIndex: 1300, // Ensure it is above other content (MUI modal default is 1300)
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden", // Hide overflow
  backgroundColor: "#fff", // Dark background for better contrast
};

const closeButtonStyles = {
  position: "absolute",
  top: 16, // Adjust as needed
  right: 16, // Adjust as needed
  color: "#000", // Close button color
};

const arrowButtonStyles = {
  width: "30px", // Adjust width for smaller arrows
  height: "30px", // Adjust height for smaller arrows
  background: "none", // Semi-transparent background for arrows
  border: "none", // Remove border
  borderRadius: "50%", // Keep round button
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  position: "absolute", // Use absolute positioning
  top: "50%", // Center vertically
  transform: "translateY(-50%)", // Center the button vertically
  zIndex: 1000, // Ensure arrows are above other content
};

const arrowIconStyles = {
  fontSize: "22px", // Adjust the size of the arrow icons
  color: "black", // Color of the arrow icons
  
};

export default ProductImageViewer;
