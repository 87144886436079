import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import axios from "axios";
import { URL_CONSTANTS } from "../Api/ApiUrl";

const ImageUploader = ({ onUploadSuccess }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);

    const compressedFiles = await Promise.all(
      files.map(async (file) => {
        try {
          // Log original file size in MB
          const originalSizeMB = (file.size / (1024 * 1024)).toFixed(2);
          console.log(`Original size of ${file.name}: ${originalSizeMB} MB`);

          // Compress the image
          const compressedFile = await compressImage(file);

          // Log compressed file size in MB
          const compressedSizeMB = (
            compressedFile.size /
            (1024 * 1024)
          ).toFixed(2);
          console.log(
            `Compressed size of ${compressedFile.name}: ${compressedSizeMB} MB`
          );

          return compressedFile;
        } catch (error) {
          console.error("Error compressing the image", error);
          return file; // If compression fails, use the original file
        }
      })
    );

    setSelectedFiles(compressedFiles);
  };

  // Compress image using Canvas API
  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 1024; // Maximum width for scaling
          const maxHeight = 1024; // Maximum height for scaling

          let width = img.width;
          let height = img.height;

          // Maintain aspect ratio while resizing
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          // Convert canvas to a Blob (compressed image)
          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            file.type,
            0.7 // Compression quality (0.7 = 70% quality)
          );
        };

        img.onerror = () => {
          reject(new Error("Failed to load image for compression"));
        };
      };

      reader.onerror = () => {
        reject(new Error("Failed to read image file"));
      };
    });
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      handleUpload();
    }
  }, [selectedFiles]);

  const handleUpload = async () => {
    setUploading(true);
    setError("");
    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("images", selectedFiles[i]);
      }

      const response = await axios.post(URL_CONSTANTS.imageUploader, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const urls = response.data.response.map((img) => img.url);
      onUploadSuccess(urls);
    } catch (error) {
      setError("Failed to upload images");
      console.error("Error uploading images", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <Box className="img_uploader">
        <input type="file" multiple onChange={handleFileChange} />

        {/* Show loading spinner when uploading */}
        {uploading && (
          <Box display="flex" alignItems="center" mt={2}>
            <CircularProgress size={24} />
            <Typography variant="body2" ml={2}>
              Uploading images, please wait...
            </Typography>
          </Box>
        )}

        {/* Show error message if there's an error */}
        {error && (
          <Typography color="error" mt={2}>
            {error}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ImageUploader;
