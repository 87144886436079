import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import { capitalizeFirstLetter } from "../Supporting files/HelpingFunction";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";


const Blogs = () => {
  const navigate = useNavigate();
  const [newBlogs, setNewBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  //Get new blog
  const getBlog = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getBlogs);
      setNewBlogs(response.response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Blogs:", error.message);
    }
  };

  useEffect(() => {
    getBlog();
  }, [])

  return (
    <>
      {loading && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="our_blogs pt-10 pb-20">
          <h1>Our Blogs</h1>
          <h2>
            Home/<span> Blogs</span>
          </h2>
        </Box>
        <Box className="blogSection">
          <h2 className="theme_heading">
            From The <span>Blog</span>
          </h2>
          <Box>
            <Grid container spacing={3}>
              {newBlogs?.map((data) => (
                <Grid xs={12} sm={6} lg={4} className="blog">
                  <div className="date"> Feb/2/2024 </div>
                  <img loading="eager" src={data.image} alt="blog" />
                  <div className="info p-6">
                    <h6>{data.title.toUpperCase(3)}</h6>
                    <p>
                      {data.description?.length > 200
                        ? capitalizeFirstLetter(
                            data.description.slice(0, 200)
                          ) + "..."
                        : capitalizeFirstLetter(data.description)}
                    </p>
                    <Grid xs={12} className="ctaBtn p-0">
                      <Button
                        variant="contained"
                        className="borderBtn"
                        onClick={() =>
                          navigate(
                            `${userRoutes.blogDetail}?blogId=${data?._id}`
                          )
                        }
                      >
                        Read More
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              ))}
              {/* <Grid xs={12} className="ctaBtn text-center mt-6 mb-10">
                <Button
                  variant="contained"
                  className="submitBtn"
                  // onClick={() => navigate(userRoutes.dashboard)}
                >
                  View All
                </Button>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Blogs;