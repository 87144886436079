import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "../Admin/admin.scss";
import CustomTable from "../Supporting files/CustomTable/CustomTable";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    authorName: "",
    image: "",
  });
  const [totalCount, setTotalCount] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [tableLimit, setTableLimit] = useState();
  useEffect(() => {
    // Fetch blogs from API
    getAllBlogs();
  }, []);
  const getAllBlogs = (page = 0, pageSize = 25) => {
    axios
      .get(
        `${ADMINURL_CONSTANTS.getAllBlogs}?page=${
          page + 1
        }&pageSize=${pageSize}`
      )
      .then((response) => {
        setTotalCount(response.data.count);
        setBlogs(
          response.data.response.map((blog) => ({ ...blog, id: blog._id }))
        );
      })
      .catch((error) => {
        console.error("Error fetching blogs", error);
      });
  };
  const handleDelete = (blogId) => {
    setDeleteDialogOpen(true);
    setSelectedBlog(blogId);
  };

  const confirmDelete = () => {
    axios
      .delete(`${ADMINURL_CONSTANTS.deleteBlogs}/${selectedBlog}`)
      .then(() => {
        setBlogs(blogs.filter((blog) => blog.id !== selectedBlog));
        setDeleteDialogOpen(false);
      })
      .catch((error) => {
        console.error("Error deleting blog", error);
        setDeleteDialogOpen(false);
      });
  };

  const handleUpdate = (blog) => {
    setFormData(blog);
    setSelectedBlog(blog.id);
    setUpdateDialogOpen(true);
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    axios
      .patch(`${ADMINURL_CONSTANTS.updateBlogs}/${selectedBlog}`, formData)
      .then((response) => {
        setBlogs(
          blogs.map((blog) =>
            blog.id === selectedBlog
              ? { ...response.data.response, id: selectedBlog }
              : blog
          )
        );
        setUpdateDialogOpen(false);
      })
      .catch((error) => {
        console.error("Error updating blog", error);
      });
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const columns = [
    { field: "title", headerName: "Title", minWidth: 200, check_status: true },
    {
      field: "description",
      headerName: "Description",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "authorName",
      headerName: "Author",
      minWidth: 150,
      check_status: true,
    },
    {
      field: "image",
      headerName: "Images",
      flex: 3,
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return (
          <img loading="eager" src={row.image} alt="Blog" style={{ width: 100, height: 100 }} />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 3,
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return (
          <Box>
            <IconButton onClick={() => handleUpdate(row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Container className="adminWrapper">
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          All Blogs
        </Typography>
        <CustomTable
          header_data={columns}
          data={blogs}
          value_update={() => {}}
          attrName={"UGTable"}
          BE_filter_code={20}
          totalRecords={totalCount}
          refetch_data={getAllBlogs}
          setTableLimit={setTableLimit}
          setTableSkip={setTableSkip}
        />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this blog?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Blog Dialog */}
      <Dialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
      >
        <DialogTitle>Update Blog</DialogTitle>
        <DialogContent>
          <form onSubmit={handleUpdateSubmit}>
            <TextField
              margin="dense"
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleFormChange}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleFormChange}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Author Name"
              name="authorName"
              value={formData.authorName}
              onChange={handleFormChange}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Image URL"
              name="image"
              value={formData.image}
              onChange={handleFormChange}
              fullWidth
              required
            />
            <DialogActions>
              <Button onClick={() => setUpdateDialogOpen(false)}>Cancel</Button>
              <Button type="submit" color="primary">
                Update
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default AllBlogs;
